import Cookies from 'js-cookie';
const baseUrl = 'https://api-dev.solutiicolaborative.ro/';

export const api = {
  get(url) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    const promise = fetch(apiUrl, {
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    return promise.then((response) => {
      if (response.status !== 204) {
        return response.json();
      }
      return null;
    });
  },

  post(url, data) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });
  },

  put(url, data) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });
  },

  delete(url, data) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });
  },

  postGoogleLogin(url, token) {
    const apiUrl = `${baseUrl}${url}`;
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },

  delete(url, data) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });
  },

  postWithFile(url, formData) {
    const apiUrl = `${baseUrl}${url}`;
    const authToken = Cookies.get('SESSION_TOKEN');
    return fetch(apiUrl, {
      mode: 'cors',
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    });
  },

  postDataWithoutAuth(url, data) {
    const apiUrl = `${baseUrl}${url}`;
    return fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};
